@import url("https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Inconsolata:wght@200..900&family=Josefin+Sans:ital,wght@0,100..350;1,100..700&family=Protest+Riot&family=Stalematewght@0,100..350&display=swap");

@font-face {
  font-family: headingFont;
  src: url(./fonts/black-mango-font-modern-beauty-family/black-mango-font-modern-beauty-family/Variable/BlackMangoVariableGX.ttf);
}
@font-face {
  font-family: headingFont-semiBold;
  src: url(./fonts/black-mango-font-modern-beauty-family/black-mango-font-modern-beauty-family/TTF/BlackMango-SemiBold.ttf);
}
@font-face {
  font-family: contentFont;
  src: url(./fonts/glacial-indifference/GlacialIndifference-Regular.otf);
}
@font-face {
  font-family: ivyMode;
  src: url(./fonts/ivy-mode.otf);
}
body {
  margin: 0;
  font-family: contentFont;
  -webkit-font-smoothing: contentFont;
  -moz-osx-font-smoothing: contentFont;
  font-style: normal;
  color: var(--font-color);
  /* background-image: url('../public/img/back.webp');
  background-size: cover;
  background-position: center; */
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  color: var(--font-color);
  text-align: justify;
}

:root {
  --primary: #5e3bee;
  --heading-color: #735f50;
  --bg-shade: #f1ebeb;
  --github: #e62872;
  --darkblue: #1c1e53;
  --black: #000000;
  --white: #ffffff;
  --button-color: #a9b9be;
  --font-color: #735f50; /* New color variable */
}
.btn {
  font-family: "Roboto";
  display: inline-block;
  padding: 14px 32px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -ms-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
}
.btn-outline-primary {
  color: var(--primary);
  background-color: var(--white);
  border: 1px solid var(--primary);
}
.btn-outline-primary:hover {
  color: var(--white);
  background-color: var(--primary);
}
.btn-primary {
  color: var(--white);
  background-color: var(--primary);
  border: 1px solid var(--primary);
}
.btn-primary:hover {
  color: var(--primary);
  background-color: var(--white);
}

.btn-github-about {
  position: relative;
  font-size: 22px;
  line-height: 22px;
  font-weight: 500;
  padding: 24px 40px;
  text-align: center;
  color: var(--e-global-color-accent);
  display: inline-block;
  background-color: #a9b9be;
  border: 2px solid #a9b9be;
  border-radius: 35px 0px 35px 0px;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

[class^="btn-github-"]::before {
  content: '';
  position: absolute;
  top: 0;
  left: -75px; /* Adjust starting position */
  width: 50px;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  /* transform: skewX(-20deg); */
  transition: all 0.3s ease-in-out;
  opacity: 0; /* Initial opacity */
}

[class^="btn-github-"]:hover::before {
  left: 100%;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

[class^="btn-github-"]:hover {
  color: #fff;
  background-color: #5e838c;
  border-color: #5e838c;
  transition: all 0.3s ease-in-out;
}

[class^="btn-github-"]:hover::before {
  animation: 0.5s ease-in-out;
}

.btn--footer {
  background-color: #f3f6f8;
  color: var(--font-color);
  border: none;
}
/* .logo {
  cursor: pointer;
} */

.logo {
  width: 124px; /* Base size */
  height: auto; /* Maintain aspect ratio */
  cursor: pointer;

  @media (max-width: 768px) {
    width: 60px; /* Adjust for smaller screens */
  }
}
/* Global CSS Ends */
/* Main Headings */

/* Heading 1 */
h1 {
  font-family: ivyMode;
  font-size: 56px;
  font-weight: 500;
  line-height: 67px;
  color: var(--font-color)
}
/* Heading 2 */
h2 {
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
}
/* Heading 3 */
h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
}
/* Pragraphs text large medium small */
/* Body 1 */
.text-lg {
  color: var(--font-color);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  font-family: devaFont;
}
/* Body 2 */
.text-md {
  color: var(--font-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
/* Body 3 */
.text-sm {
  color: var(--font-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

/* Navbar Style Start */
.custom-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: transparent;
  background-image: linear-gradient(to bottom, #f1ebeb, transparent);
  box-shadow: 0px 10px 26px 3px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: background-color 0.3s ease;
}

.custom-navbar.active {
  background-color: var(--bg-shade);
}

.custom-navbar .navbar--items {
  display: flex;
}

.custom-navbar .navbar--items ul {
  list-style: none;
  display: flex;
  align-items: flex-end;
  gap: 42px;
  text-decoration: none;
}

.custom-navbar .navbar--items ul li a {
  text-decoration: none;
  color: var(--font-color);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
}

.custom-navbar .navbar--active-content {
  color: var(--primary);
}

.custom-navbar .nav__hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  gap: 5px;
}

.custom-navbar .nav__hamburger__line {
  width: 30px;
  height: 3px;
  background-color: var(--darkblue);
  transition: all 0.3s ease;
}

.custom-navbar .nav__hamburger.active .nav__hamburger__line:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.custom-navbar .nav__hamburger.active .nav__hamburger__line:nth-child(2) {
  opacity: 0;
}

.custom-navbar .nav__hamburger.active .nav__hamburger__line:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

.navbar--dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: -65px;
  background-color: transparent; /* Transparent background */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  display: none;
  flex-direction: column;
  padding: 5px 0; /* Less padding */
  border: 1px solid rgba(255, 255, 255, 0.8); /* Transparent border to maintain box model */
}

.navbar--dropdown:hover .dropdown-menu {
  display: flex;
}

.dropdown-menu a {
  padding: 8px 10px; /* Less padding for less gap */
  text-align: center; /* Centered text */
  cursor: pointer;
}

.dropdown-menu a:hover {
  background-color: rgba(241, 241, 241, 0.8);
}

.navbar--dropdown .navbar--content::after {
  content: ' ▼'; /* Dropdown icon */
  font-size: 12px;
  margin-left: 5px;
  background: transparent;
}


@media (max-width: 1200px) {
  .custom-navbar .navbar--items {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px; /* Adjust based on your design */
    left: 0;
    width: 100%;
    background-color: var(--bg-shade);
    padding: 10px 20px; /* Adjust padding as needed */
    box-shadow: 0px 10px 26px 3px rgba(0, 0, 0, 0.1);
    z-index: 999; /* Ensure it's above the navbar */
  }

  .custom-navbar .navbar--items.active {
    display: flex;
  }

  .custom-navbar .navbar--items ul {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  .custom-navbar .nav__hamburger {
    display: flex;
  }
}
/* Navbar Styler Ends */

.hero--section {
  display: flex;
  flex-direction: column;
  padding: 133.333px 85.333px;
  justify-content: space-between;
  
  height: 600px;
  transition: padding 0.3s ease-in-out, height 0.3s ease-in-out;
}

.hero--section.home {
  background: url("../public/img/full-story.jpg") center/cover var(--bg-shade);
}

.hero--section.about-section {
  background: url("../public/img/ho.jpg") center/cover var(--bg-shade);
}

.hero--section.retreat-index {
  background: url("../public/img/retreat/ret.jpg") center/cover var(--bg-shade);
}

.hero--section.retreat {
  background: url("../public/img/retreat.jpg") center/cover var(--bg-shade);
}

.hero--section.retreat-np {
  background: url("../public/img/retreat/np-c.jpg") center/cover var(--bg-shade);
}

.hero--section.journey {
  background: url("../public/img/yoga2.png") center/cover var(--bg-shade);
}

/* .hero--section.yoga {
  background: url("../public/img/npp2.jpg") center/cover var(--bg-shade);
} */

.hero--section.yoga {
  /* position: relative; */
  background: url("../public/img/n-b.jpg") center/cover no-repeat;
  background-position: center bottom; /* Align the image to the bottom center */
  background-size: cover;
   /* Adjust the height as needed */
  overflow: hidden;
  opacity: 0.75;
}



.hero--section.nutrition {
  background: url("../public/img/nutrition-banner.jpg") center/cover var(--bg-shade);
}

.hero--section.sound {
  background: url("../public/img/s-banner.jpg") center/cover var(--bg-shade);
}

.hero--section.massage {
  background: url("../public/img/m-banner.jpg") center/cover var(--bg-shade);
}

.hero--section--content--box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.hero--section--content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 40px;
  gap: 21.333px;
}

.hero--section--title {
  color: floralwhite;
  font-size: 90px;
  font-weight: 600;
  line-height: 90px;
  align-self: stretch;
}

.hero--section--title--color {
  font-family: headingFont;
  font-size: 95px;
  color: var(--bg-shade);
}

.hero--section-quote {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: headingFont;
  font-size: 27px;
  color: var(--bg-shade);
  font-style: italic;
}

.hero--section-description {
  color: var(--font-color);
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
}

.hero--section--img {
  display: flex;
  opacity: 0.75;
}

.hero--section--img > img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

@media only screen and (max-width: 768px) {
  .hero--section {
    padding: 80px 40px;
    height: auto;
    min-height: 400px; /* Ensure content is not squished */
  }

  .hero--section--title--color {
    font-size: 90px;
  }

  .hero--section-quote {
    font-size: 18px;
    align-items: flex-start;
    margin-top: 20px; /* Adjust spacing */
  }

}

@media only screen and (max-width: 480px) {
  .hero--section {
    padding: 60px 20px;
    height: auto;
    min-height: 300px; /* Ensure content is not squished */
  }

  .hero--section--title--color {
    font-size: 70px; /* Adjust font size */
  }

  .hero--section-quote {
    font-size: 16px;
    align-items: flex-start;
    margin-top: 15px; /* Adjust spacing */
  }
}

/* About section starts */

.about--section {
  display: grid;
  padding: 133.3px 85.3px 0 85.3px;
  align-items: start;
  gap: 114.6px;
  grid-template-columns: repeat(2, 1fr);
  
}

.about--section--img {
  padding-left: 50px;
  display: grid;
  width: 860px;
  height: 880px;
  border-radius: 20px;
}

.about--section--img > img {
  width: 100%;
  height: 100%;
}

.skills-section--heading {
  color: floralwhite;
  font-size: 90px;
  font-weight: 600;
  line-height: 90px;
  align-self: stretch;
}

.hero--section-description {
  color: var(--font-color);
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
}

@media only screen and (max-width: 768px) {
  .about--section {
    padding: 80px 40px;
    grid-template-columns: 1fr;
  }

  .about--section--img {
    width: 100%;
    height: auto;
    padding-left: 0;
  }

  .skills-section--heading {
    font-size: 48px;
    line-height: 54px;
  }
}
.text-box {
  position: absolute;
  left: -20%; /* Adjust this value to control the overlap */
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.8); /* Slightly transparent background */
  padding: 20px;
  border-radius: 10px;
  /* max-width: 120%; Ensure the text box width is constrained */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: add a subtle shadow for better visibility */
  text-align: left;
  color: var(--font-color); /* Apply new font color */
}

.text-box-right {
  position: absolute;
  right: -20%; /* Adjust this value to control the overlap */
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.8); /* Slightly transparent background */
  padding: 20px;
  border-radius: 10px;
  max-width: 100%; /* Ensure the text box width is constrained */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: add a subtle shadow for better visibility */
  text-align: left;
  color: var(--font-color); /* Apply new font color */
}

.text-box h2,
.text-box-right h2 {
  margin-bottom: 10px;
  font-size: 1.5em;
  font-weight: bold;
}

.text-box p,
.text-box-right p {
  font-size: 1em;
}

.btn-github-portfolio {
  position: relative;
  /* font-size: 22px; */
  /* line-height: 22px; */
  font-weight: 500;
  padding: 15px 20px;
  text-align: center;
  color: var(--e-global-color-accent);
  display: inline-block;
  background-color: #a9b9be;
  border: 2px solid #a9b9be;
  border-radius: 35px 0px 35px 0px;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

@media (max-width: 768px) {
  .text-box,
  .text-box-right {
    position: static;
    transform: none;
    max-width: 100%;
    margin-top: 20px;
  }
}

.wave-container {
  position: relative;
  width: 100%;
  /* overflow: hidden; */
  z-index: 1;
  margin-bottom:-45px;

}

.wave {
  position: absolute;
  bottom: -53px;
  left: 0;
  width: 100%;
}

@media (max-width: 576px) {
  .section1, .section2 {
    padding: 60px 0;
  }
}

.wave-about {
  position: absolute;
  bottom: -230px;
  left: 0;
  width: 100%;
}


.wave-container-retreat {
  position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: -20px; /* Adjust this value to bring the sections closer or further down from the wave */
}

.wave-retreat {
  position: absolute;
  bottom: 17px;
  left: 0;
  width: 100%;
}

.wave-container-retreat1 {
  position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: -20px; /* Adjust this value to bring the sections closer or further down from the wave */
}

.wave-retreat1 {
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 100%;
}

.wave-container-retreat2 {
  position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: 50px /* Adjust this value to bring the sections closer or further down from the wave */
}

.wave-retreat2 {
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 100%;
}

.wave-container-about {
  position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: 254px; /* Adjust this value to bring the sections closer or further down from the wave */
}

.wave-portfolio {
  position: absolute;
  bottom: -240px;
  left: 0;
  width: 100%;
}

.wave-container-portfolio {
  position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: 262px; /* Adjust this value to bring the sections closer or further down from the wave */
}

.wave-container-journey {
  position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: -1px; /* Adjust this value to bring the sections closer or further down from the wave */
}

.wave-journey {
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
}

.wave-container-journey2 {
  position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: 200px; /* Adjust this value to bring the sections closer or further down from the wave */
}

.wave-journey2 {
  position: absolute;
  bottom: -166px;
  left: 0;
  width: 100%;
}

.wave-container-instagram {
  position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: 170px; /* Adjust this value to bring the sections closer or further down from the wave */
}

.wave-instagram {
  position: absolute;
  bottom: -150px;
  left: 0;
  width: 100%;
}


.image-container {
  position: relative;
  display: inline-block;
  width:100%
}

.fixed-img {
  height: 300px; /* Set a fixed height */
  width: 100%;
  object-fit: cover; /* Ensures the image covers the given dimensions */
}

.quote {
  position: absolute;
  top: 50%;
  left: 10px; /* Adjust this value to set the distance from the left edge */
  transform: translateY(-50%);
  color: white; /* Change this to suit your image's colors */
  padding: 10px;
  border-radius: 5px;
  text-align: left;
  font-size: 27px; /* Adjust the font size as needed */
  font-family: headingFont;
}

/* Contact Section starts */
.contact-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px 20px;
  gap: 40px;
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); */
  margin: 20px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 100s ease-out;
}

.contact-intro::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../public/img/yoga2.png') no-repeat center center/cover; /* Ensure to replace with the actual path to your image */
  z-index: 1;
  opacity: 0.5; /* Adjust opacity as needed to ensure text readability */
}

.contact-intro {
  position: relative;
  z-index: 2; /* Ensure the text is above other content */
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent background to make the text readable over the image */
  padding: 10px;
  border-radius: 8px;
}

.contact-section.hidden {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 100s ease-out;
}

.contact-section.visible {
  opacity: 1;
  transform: translateY(0);
}

.contact-section-heading h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: var(--font-color)
}

.contact-section-heading p {
  font-family: 'contentFont';
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.contact-section-heading-bottom {
  font-weight: bold;
  color: var(--font-color)
}

.contact-section-lists {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 100%;
}

.contact-section-item {
  flex: 1 1 calc(50% - 20px);
  max-width: calc(50% - 20px);
  background: #f9f9f9;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transition: transform 0.3s;
}

.contact-section-item:hover {
  transform: translateY(-10px);
}

.contact-item-heading {
  font-family: 'contentFont';
  color: #a9b9be;
  font-size: 1.75rem;
  margin-bottom: 10px;
}

.contact-item-info p {
  font-family: 'contentFont';
  font-size: 1rem;
  margin: 0;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .contact-section-item {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }

  .contact-section-heading h1 {
    font-size: 2rem;
  }

  .contact-section-heading p {
    font-size: 1.125rem;
  }

  .contact-item-heading {
    font-size: 1.5rem;
  }

  .contact-item-info p {
    font-size: 0.875rem;
  }
}

@media (max-width: 768px) {
  .wave {
    bottom:-12px;
  }

  .wave-portfolio {
    bottom: -100px;
  }

  .wave-about {
    bottom:9px;
  }

  .wave-instagram{
    bottom:42px;
  }

  .wave-container-instagram{
    margin-bottom: -47px;
  }

  .wave-container-about {
    margin-bottom:-35px
  }

  .wave-container-portfolio {
    margin-bottom: 100px;
  }

  .wave-container-retreat {
    margin-bottom: -20px;
  }

  .wave-container-retreat2 {
    margin-bottom: 10px;
  }

  .wave-container-journey {
    margin-bottom: -51px; /* Adjust this value to bring the sections closer or further down from the wave */
  }
  
  .wave-journey {
    bottom: 50px;
  }

  .wave-container-journey2 {
    margin-bottom: 26px; /* Adjust this value to bring the sections closer or further down from the wave */
  }
  
  .wave-journey2 {
    bottom: 22px;
  }

  .contact-section-item {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .contact-section-heading h1 {
    font-size: 1.75rem;
  }

  .contact-section-heading p {
    font-size: 1rem;
  }

  .contact-item-heading {
    font-size: 1.25rem;
  }

  .contact-item-info p {
    font-size: 0.875rem;
  }
}

@media (max-width: 480px) {
  .wave-portfolio {
    bottom: -56px;
  }

  .wave-about {
    bottom:-41px;
  }

  .wave-container-about {
    margin-bottom: 64px;
  }

  .wave-container-portfolio {
    margin-bottom: 80px;
  }


  .wave-container-retreat {
    margin-bottom: -20px;
  }

  .wave-container-retreat2 {
    margin-bottom: 20px;
  }

  .wave-retreat2 {
    bottom:10px;
  }

  .wave-instagram{
    bottom:42px;
  }

  .wave-container-instagram{
    margin-bottom: -47px;
  }

  .wave-container-journey {
    margin-bottom: -51px; /* Adjust this value to bring the sections closer or further down from the wave */
  }
  
  .wave-journey {
    bottom: 50px;
  }

  .wave-container-journey2 {
    margin-bottom: 26px; /* Adjust this value to bring the sections closer or further down from the wave */
  }
  
  .wave-journey2 {
    bottom: 22px;
  }

  .contact-section-heading h1 {
    font-size: 1.5rem;
  }

  .contact-section-heading p {
    font-size: 0.875rem;
  }

  .contact-item-heading {
    font-size: 1rem;
  }

  .contact-item-info p {
    font-size: 0.75rem;
  }
}
/* Contact Section ends */

.footer--container {
  display: flex;
  padding: 30px 85.333px;
  flex-direction: column;
  align-items: flex-start;
  background: #f3f6f8;
  align-items: center;
  align-self: stretch;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
}

.footer--link--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  flex-wrap: wrap;
}

.footer--items > ul {
  list-style: none;
  display: flex;
  align-items: flex-start;
  gap: 42.667px;
  text-decoration: none;
}

.footer--items ul > li > a {
  text-decoration: none;
}

.footer--social--icon > ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  text-decoration: none;
}

.divider {
  margin: 106px 0 42.67px;
  height: 1.333px;
  width: 100%;
  background: rgba(40, 41, 56, 0.55);
}

.footer--content--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.footer--content {
  color: var(--font-color);
  font-size: 18.667px;
  font-weight: 400;
  line-height: 28px;
}

/* Media Queries for Responsiveness */
@media (max-width: 1024px) {
  .footer--container {
    padding: 20px 40px;
  }

  .footer--link--container {
    flex-direction: column;
    align-items: center;
  }

  .footer--items > ul {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .footer--social--icon > ul {
    justify-content: center;
    gap: 10px;
  }

}

@media (max-width: 768px) {
  .footer--container {
    padding: 15px 20px;
  }

  .footer--link--container {
    flex-direction: column;
    align-items: center;
  }

  .footer--items > ul {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .footer--social--icon > ul {
    justify-content: center;
    gap: 8px;
  }

  .footer--content {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (max-width: 480px) {
  .footer--container {
    padding: 10px 10px;
  }

  .footer--link--container {
    flex-direction: column;
    align-items: center;
  }

  .footer--items > ul {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .footer--social--icon > ul {
    justify-content: center;
    gap: 5px;
  }

  .footer--content {
    font-size: 14px;
    line-height: 20px;
  }
}

/* my-journey starts */
.my--journey--section {
  background-color: var(--bg-shade);
  padding: 80px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.my--journey--content--box {
  max-width: 1200px;
  width: 100%;
  margin-bottom: 40px;
}

.my--journey--heading {
  font-family: headingFont-semiBold;
  text-align: center;
  font-size: 2rem;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #fff;
}

.my--journey--content--with--photos > p,
.my--journey--content {
  font-family: contentFont;
  color: #fff;
  font-size: 1.25rem;
  font-weight: 250;
  margin-bottom: 20px;
  text-align: justify;
}

.my--journey--info,
.my--journey--info--vipasana {
  font-family: contentFont;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 250;
  margin-bottom: 20px;
  text-align: justify;
  width: 100%;
}

.courses--quote {
  font-family: contentFont;
  padding: 20px 0;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 400;
  font-style: italic;
  text-align: center; 
}

.courses--quote p {
  border-left: 4px solid #000; /* Adjust the color and width as needed */
  padding-left: 10px; /* Add some padding to create space between the border and the text */
  margin-left: 15px; /* Optional: Add margin to the left if needed */
}

.my--journey--content--with--photos {
  display: flex;
  flex-direction: column;
}

.my--journey--photos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.my--journey--photos img {
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
}

.my--journey--ending {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

/* Parallax Effect */
.parallax {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.parallax-bali {
  background-image: url('../public/img/bali1.jpg');
}

.parallax-rishikesh {
  background-image: url('../public/img/rishikesh1.jpg');
}

.parallax-vipassana {
  background-image: url('../public/img/vipasana1.jpg');
}

.parallax-varanasi {
  background-image: url('../public/img/varanasi2.jpg');
}

/* Media Queries */
@media (max-width: 1200px) {
  .my--journey--section {
    padding: 80px 20px;
  }

  .my--journey--heading {
    font-size: 1.75rem;
  }

  .my--journey--content--with--photos > p,
  .my--journey--content,
  .my--journey--info,
  .my--journey--info--vipasana {
    font-size: 1.125rem;
  }

  .my--journey--photos img {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .my--journey--section {
    padding: 60px 10px;
  }

  .my--journey--heading {
    font-size: 1.5rem;
  }

  .my--journey--content--with--photos > p,
  .my--journey--content,
  .my--journey--info,
  .my--journey--info--vipasana {
    font-size: 1rem;
  }

  .my--journey--photos {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .my--journey--section {
    padding: 40px 10px;
  }

  .my--journey--heading {
    font-size: 1.25rem;
  }

  .my--journey--content--with--photos > p,
  .my--journey--content,
  .my--journey--info,
  .my--journey--info--vipasana {
    font-size: 0.875rem;
  }
}
/* my-journey-ends */

.card {
  border: none;
  border-radius: 91px 0 91px 0;
  overflow: hidden;
  animation: float_img 6s ease-in-out infinite;
  transition: all 0.3s ease-in-out;
}

.card-img {
  border-radius: 91px 0 91px 0;
  filter: brightness(70%);
}

.card-img-overlay {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px;
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0;
  color: white;
}

.blog_posts_image_content{
  position: absolute;
  bottom: 14px;
  left: 7px;
}
.blog_posts_image_content span{
  /* font-size: 15px; */
  font-size:14px;
  /* line-height: 18px; */
  line-height: 15px;
  font-weight: 400;
  color: var(--e-global-color-white);
  background-color: #5e838c;
  padding: 6px 10px;
  border-radius: 15px 0 15px 0;
  margin-bottom: 5px;
  display: inline-block;
}
.blog_posts_image_content h5{
  color: var(--e-global-color-white);
  margin-bottom: 22px;
  padding-right: 30px;
  margin-left: 5px;
}
.blog_posts_image_content i{
  font-size: 20px;
  line-height: 20px;
  color:white;
  transition: all 0.3s ease-in-out;
}

.blog_posts_image_content:hover.blog_posts_image_content i{
  color: #5e838c;
}

.card:hover{
  transform: translateY(-10px);
}

.card:hover i {
  color: #5e838c;
}

.scrollable-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  overflow: hidden; /* Prevent scrollbar from showing */
}

.scroll-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
  height: 50px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  z-index: 10;
}

.scroll-button.left {
  left: 0;
}

.scroll-button.right {
  right: 0;
}

.scrollable-images {
  display: flex;
  overflow-x: hidden; /* Hide scrollbar */
  scroll-behavior: smooth;
  width: calc(100% - 100px); /* Adjust to leave space for the buttons */
  margin: 0 50px; /* Adjust to leave space for the buttons */
}

.img-container {
  flex: 0 0 auto;
  margin: 0 10px;
  width: calc(33.33% - 20px); /* Ensure three images fit in the viewport with margins */
  display: inline-block;
}

.img-container img {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 350px; /* Adjust the height to make it a bit more */
  object-fit: cover;
  width: 100%;
}

/* Responsive design */
@media (max-width: 1200px) {
  .img-container {
    width: calc(50% - 20px); /* Two images per row */
  }
}

@media (max-width: 768px) {
  .img-container {
    width: calc(100% - 20px); /* One image per row */
  }
}

@media (max-width: 576px) {
  .scroll-button {
    font-size: 18px;
    height: 40px;
    width: 40px;
  }

  .img-container img {
    height: 250px; /* Adjust the height for smaller screens */
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}
.heading {
  margin-bottom: 20px;
}
.heading h1 {
  margin: 0;
  font-size: 24px;
  color: #8e7b72;
}
.heading a {
  text-decoration: none;
  color: #8e7b72;
  font-size: 18px;
}
.grid {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}
.grid a {
  display: block;
  width: 270px;
  height: 375px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  position: relative;
}
.grid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px; /* Adjust the size as needed */
  color: white;
  opacity: 0.8;
}

.img-responsive {
  width: 100%;
  height: 400px;
  object-fit: fill;
  margin-top: -2%;
}

@media (max-width: 768px) {
  /* For mobile and smaller devices */
  .img-responsive {
    margin-top: -7%;
  }
}

.custom-line {
  width: 14%; /* Use percentage for responsiveness */
  height: 2px;
  background-color: black;
  margin: 20px auto; /* Centers the line horizontally */
}

@media (max-width: 768px) {
  .custom-line {
    width: 50%; /* Adjusts width for tablet screens */
  }
}

@media (max-width: 480px) {
  .custom-line {
    width: 70%; /* Adjusts width for mobile screens */
  }
}















